import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";

const Table = ({ className, ...props }: ComponentProps<"table">) => (
  <div className="relative flex w-full flex-shrink flex-grow basis-0 flex-col overflow-auto rounded border">
    <table
      className={cn("w-full caption-bottom text-sm", className)}
      {...props}
    />
  </div>
);
Table.displayName = "Table";

const TableHeader = ({ className, ...props }: ComponentProps<"thead">) => (
  <thead
    className={cn("[&_tr]:border-b", "sticky top-0 z-[1] bg-gray-100", className)}
    {...props}
  />
);
TableHeader.displayName = "TableHeader";

const TableBody = ({ className, ...props }: ComponentProps<"tbody">) => (
  <tbody
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
);
TableBody.displayName = "TableBody";

const TableFooter = ({ className, ...props }: ComponentProps<"tfoot">) => (
  <tfoot
    className={cn("bg-muted/50 border-t font-medium [&>tr]:last:border-b-0", className)}
    {...props}
  />
);
TableFooter.displayName = "TableFooter";

const TableRow = ({ className, ...props }: ComponentProps<"tr">) => (
  <tr
    className={cn("hover:bg-muted/50 data-[state=selected]:bg-muted border-b transition-colors", className)}
    {...props}
  />
);
TableRow.displayName = "TableRow";

const TableHead = ({ className, ...props }: ComponentProps<"th">) => (
  <th
    className={cn(
      "text-component-fg h-10 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
      className
    )}
    {...props}
  />
);
TableHead.displayName = "TableHead";

const TableCell = ({ className, ...props }: ComponentProps<"td">) => (
  <td
    className={cn("p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]", className)}
    {...props}
  />
);
TableCell.displayName = "TableCell";

const TableCaption = ({ className, ...props }: ComponentProps<"caption">) => (
  <caption
    className={cn("text-component-fg mt-4 text-sm", className)}
    {...props}
  />
);
TableCaption.displayName = "TableCaption";

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption };
