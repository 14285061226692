import NewTask from "./NewTask.png";
import { EMRPhysician } from "@/lib/types/physician";
import Image from "next/image";
import { useState } from "react";
import Icon from "@mdi/react";
import { mdiBellBadgeOutline, mdiBellOutline } from "@mdi/js";
import { NotificationsTable } from "@/components/notificationsTable/notificationsTable";
import { Dialog as SCNDialog, DialogContent } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scrollArea";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hoverCard";
import { isNil } from "lodash";

import { Avatar as SCNAvatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useQuery } from "@tanstack/react-query";
import userAPI from "@/api/userAPI";

function getInitials(name?: string): string {
  if (!name) {
    return "";
  }

  if (name.split(" ").length === 2) {
    return name
      .split(" ")
      .map((word) => word[0].toUpperCase())
      .join();
  }

  return name
    .split(" ")
    .slice(1, 3)
    .map((word) => word[0].toUpperCase())
    .join("");
}

interface LoggedInUserProps {
  setNotesOpen: (notesOpen: boolean) => void;
}

export const LoggedInUser = ({ setNotesOpen }: LoggedInUserProps) => {
  const [notificationsModalVisible, setNotificationsModalVisible] = useState(false);

  const { data: user } = useQuery({
    queryKey: ["user"],
    queryFn: userAPI.getUser,
    staleTime: Infinity,
  });

  const { data: numNewDoctorFiles } = useQuery({
    queryKey: ["newDoctorFiles"],
    queryFn: () => userAPI.getNewDoctorFilesList(),
    select: (data) => data.filter((file) => !file.archived).length,
    // enabled: !isNil(user.id),
    staleTime: 60 * 1000,
  });

  const onCloseDialog = () => {
    setNotificationsModalVisible(false);
  };

  if (!user) {
    return <div className="h-[93px]"></div>;
  }

  return (
    <div className="flex h-full flex-col items-end justify-start pr-1 pt-2">
      <SCNDialog
        open={notificationsModalVisible}
        onOpenChange={onCloseDialog}
      >
        <DialogContent
          className="flex max-h-[80vh] max-w-full pt-9 lg:max-w-[60vw]"
          hideTitle
        >
          <ScrollArea className="flex flex-1">
            <NotificationsTable setNotificationsModalVisible={setNotificationsModalVisible} />
          </ScrollArea>
        </DialogContent>
      </SCNDialog>

      <div className="flex gap-x-2">
        {user?.type === "doctor" && (
          <div
            className="py-0.75 relative inline-flex cursor-pointer items-center rounded px-1 text-xs font-medium"
            onClick={() => setNotificationsModalVisible(true)}
          >
            {!isNil(numNewDoctorFiles) && numNewDoctorFiles > 0 ? (
              <>
                <span className="items absolute -right-0.5 -top-0.5 flex size-5 items-center justify-center rounded-full bg-red-600">
                  <span className="font-bold text-white">{numNewDoctorFiles > 9 ? "9+" : numNewDoctorFiles}</span>
                </span>
                <Icon
                  path={mdiBellBadgeOutline}
                  size={1.25}
                />
              </>
            ) : (
              <Icon
                path={mdiBellOutline}
                size={1.25}
              />
            )}
          </div>
        )}

        <HoverCard>
          <HoverCardTrigger>
            <SCNAvatar className="">
              <AvatarImage />
              <AvatarFallback>{getInitials(user?.name)}</AvatarFallback>
            </SCNAvatar>
          </HoverCardTrigger>
          <HoverCardContent
            side="bottom"
            className="flex w-fit flex-col gap-2 p-4"
          >
            <div className="text-base font-bold">{user?.name}</div>
            <div className="text-base font-normal italic">{user?.email}</div>
          </HoverCardContent>
        </HoverCard>
      </div>
      <div className="mt-2 flex flex-row">
        <div
          className="mt-[5px] flex cursor-pointer flex-row items-center gap-2.5 text-primary-fg-blue"
          onClick={() => setNotesOpen(true)}
        >
          <Image
            src={NewTask}
            alt="New task"
          />
          <div className="text-base font-bold text-primary-fg-blue">New Task</div>
        </div>
      </div>
    </div>
  );
};
