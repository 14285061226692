import { Pharmacy } from "@/lib/types/pharmacy";
import { baseAPIClient } from "./BaseAPIClient";
import { BackendPharmacyInterface } from "@/lib/types/pharmacy";

const searchPharmacies = (query: string, callback: Function) => {
  callback(baseAPIClient.post("/pharmacy/search", { query }));
};

const getPharmacy = async (id: number) => {
  try {
    const ret = await baseAPIClient.get(`/pharmacy/${id}`);
    return ret.data.pharmacy as Pharmacy;
  } catch (e) {
    console.error(e);
    throw new Error(`Error with getting pharmacy with id: ${id}`);
  }
};

const getPharmacies = async () => {
  const resp = await baseAPIClient.get("/pharmacy");

  return resp;
};

const updatePharmacy = (pharmacy: BackendPharmacyInterface, callback: Function) => {
  callback(baseAPIClient.put("/pharmacy", pharmacy));
};

const createPharmacy = (pharmacy: BackendPharmacyInterface, callback: Function) => {
  callback(baseAPIClient.post("/pharmacy", pharmacy));
};

const pharmacyAPIClient = {
  searchPharmacies,
  getPharmacy,
  getPharmacies,
  updatePharmacy,
  createPharmacy,
};

export default pharmacyAPIClient;
