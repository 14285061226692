import { AddressBookPhysician } from "@/lib/types/addressBook";
import customFetch from "./customFetch";

const getAddressPhysiciansElastic = async (query: string): Promise<AddressBookPhysician[]> => {
  const res = await customFetch("/physician/search", { body: { query }, method: "POST" });
  return res.physicians.hits.hits.map((v: { _source: AddressBookPhysician }) => v?._source) as AddressBookPhysician[];
};

const getAddressBookPhysicianById = async (physicianId: number): Promise<AddressBookPhysician> => {
  const { physician } = await customFetch(`/physician/${physicianId}`);
  return physician;
};

const getAddressBookPhysicians = async (): Promise<AddressBookPhysician[]> => {
  const { physicians } = await customFetch(`/physician`);
  return physicians;
};

const updateAddressBookPhysician = async (physicianPayload: AddressBookPhysician): Promise<AddressBookPhysician> => {
  const { physician } = await customFetch(`/physician/update`, { body: physicianPayload, method: "POST" });
  return physician;
};

const createAddressBookPhysician = async (physicianPayload: AddressBookPhysician): Promise<AddressBookPhysician> => {
  const { physician } = await customFetch(`/physician/create`, { body: physicianPayload, method: "POST" });
  return physician;
};

const physicianAPI = {
  getAddressPhysiciansElastic,
  getAddressBookPhysicianById,
  getAddressBookPhysicians,
  updateAddressBookPhysician,
  createAddressBookPhysician,
};

export default physicianAPI;
