import { Col, Row, Typography, Modal, Divider, Carousel } from "antd";
import { UpSquareFilled, DownSquareFilled, CaretRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import Icon from "@mdi/react";
import { mdiMessageOffOutline, mdiEmailOffOutline, mdiPhoneOff, mdiLock, mdiAccountEditOutline, mdiContentCopy } from "@mdi/js";
import { Popover as SCNPopover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Tooltip as SCNTooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Button as SCNButton } from "@/components/ui/button";

import { selectedAppointmentAtom, encounterNoteIdAtom, selectedPatientAtom } from "@/lib/states/globalStates";
import { EMRPatient, PrimaryAltContact } from "@/lib/types/patient";

import { useEffect, useRef, useState } from "react";
import { EMRAppointment } from "@/lib/types/appointment";
import { isNil } from "lodash";

import { differenceInYears, format as formatFNS, parse as parseFNS } from "date-fns";
import { insuranceTypeColours } from "@/lib/types/constants";
import { LoggedInUser } from "./loggedInUser";
import { CarouselRef } from "antd/es/carousel";

import patientAPIClient from "@/api/PatientAPIClient";
import { EditPatientInfoModal } from "@/components/editPatientInfoModal/editPatientInfoModal";
import "./persistentHeader.scss";
import { ImageCropper } from "@/components/ImageCropper/ImageCropper";
import Image from "next/image";

import OHIPImage from "@/public/OHIP.svg";
import AlbertaImage from "@/public/Alberta.svg";
import PrivatePayImage from "@/public/Private pay.svg";
import BlueCrossImage from "@/public/Blue Cross.svg";
import OtherImage from "@/public/Other.svg";
import CheckmarkImage from "@/public/Checkmark.svg";
import XMarkImage from "@/public/Xmark.svg";
import CardImage from "@/public/Card.svg";
import CardBlackImage from "@/public/Card-Black.svg";
import { cn } from "@/lib/utils/cssUtils";
import physicianAPI from "@/api/physicianAPI";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import patientAPI from "@/api/patientAPI";
import { Textarea as SCNTextarea } from "@/components/ui/textarea";
import { Card as SCNCard, CardHeader, CardTitle, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "sonner";

const basePath = process.env.NEXT_PUBLIC_BASENAME ?? "";

interface PersistentHeaderProps {
  setNotesOpen: (notesOpen: boolean) => void;
}

export const PersistentHeader = ({ setNotesOpen }: PersistentHeaderProps) => {
  const queryClient = useQueryClient();
  const [selectedPatient, setSelectedPatient] = useAtom(selectedPatientAtom);
  const [, setEncounterNoteId] = useAtom(encounterNoteIdAtom);
  const [doctorName, setDoctorName] = useState("");
  const [doctorBillingNumber, setDoctorBillingNumber] = useState("");
  const [showGlobalMessage, setShowGlobalMessage] = useState<boolean>(false);
  const [showEditPatientInfoModal, setShowEditPatientInfoModal] = useState<boolean>(false);
  const [primaryAltContact, setPrimaryAltContact] = useState<PrimaryAltContact>({} as PrimaryAltContact);
  const [preferredContactMethod, setPreferredContactMethod] = useState<string>("");
  const [selectedAppointment, setSelectedAppointment] = useAtom(selectedAppointmentAtom);
  const [patientGlobalMessage, setPatientGlobalMessage] = useState<string>("");

  const updatePatientDataMutation = useMutation({
    mutationFn: (patientData: EMRPatient) => patientAPI.updatePatient(patientData),
    onSuccess: (patientData) => {
      queryClient.setQueryData(["selectedPatientData", selectedPatient.id], patientData);
      toast.info(`Patient's global message updated successfully.`);
    },
  });

  const onClear = () => {
    setSelectedPatient({} as EMRPatient);
    setEncounterNoteId(null);
  };

  const getRefDoctor = async (physicianId: number) => {
    const physician = await physicianAPI.getAddressBookPhysicianById(physicianId);
    return physician;
  };

  const getContactInfo = async (relationId: number) => {
    const res = await patientAPIClient.getPatientContactInfo(relationId);
    const contactInfo = res.data.contact;
    return contactInfo;
  };

  useEffect(() => {
    if (selectedPatient?.id !== selectedAppointment.patientId) {
      setSelectedAppointment({
        notes: "",
        reason: "",
        appointmentId: null,
      } as EMRAppointment);
    }

    if (selectedPatient?.patientReferringDoctorId) {
      getRefDoctor(selectedPatient.patientReferringDoctorId).then((physician) => {
        setDoctorName(physician.firstName && physician.lastName ? physician.lastName + ", " + physician.firstName : "");
        setDoctorBillingNumber(physician.billingNumber ? physician.billingNumber : "");
      });
    }

    if (!isNil(selectedPatient?.preferredContactId)) {
      getContactInfo(selectedPatient.preferredContactId).then((contactInfo) => {
        setPrimaryAltContact(contactInfo);
        setPreferredContactMethod(contactInfo.preferredContactMethod);
      });
    }
    else {
      setPreferredContactMethod(selectedPatient?.preferredContactMethod ?? "");
    }
  }, [selectedPatient]);

  const getInsuranceBackgroundColour = (insurer: string | null, opacity: string = "FF") => {
    if (Object.keys(selectedPatient ?? {}).length === 0) {
      return "white";
    }
    return insurer && Object.keys(insuranceTypeColours ?? {}).includes(insurer)
      ? insuranceTypeColours[insurer as keyof typeof insuranceTypeColours] + opacity
      : insuranceTypeColours["No insurance and will pay directly"] + opacity;
  };

  const getInsuranceIcon = (insurer: string | null) => {
    if (Object.keys(selectedPatient ?? {}).length === 0) {
      return "";
    }
    switch (insurer) {
      case "Ontario":
        return (
          <Image
            className="h-5 w-3"
            src={OHIPImage}
            alt="OHIP"
          />
        );
      case "Alberta":
        return (
          <Image
            className="h-5 w-3"
            src={AlbertaImage}
            alt="Alberta"
          />
        );
      case "Medaview Blue Cross":
        return (
          <Image
            className="h-5 w-3"
            src={BlueCrossImage}
            alt="Medavie Blue Cross"
          />
        );
      case "Private or other insurance":
      case "No insurance and will pay directly":
        return (
          <Image
            className="h-5 w-3"
            src={PrivatePayImage}
            alt="Private pay"
          />
        );
      case "British Columbia":
      case "Manitoba":
      case "New Brunswick":
      case "Newfoundland and Labrador":
      case "Northwest Territories":
      case "Nova Scotia":
      case "Nunavut":
      case "Prince Edward Island":
      case "Quebec":
      case "Saskatchewan":
      case "Yukon":
        return (
          <Image
            className="h-5 w-3"
            src={OtherImage}
            alt="Other Province/Territory"
          />
        );
      default:
        return (
          <Image
            className="h-5 w-3"
            src={PrivatePayImage}
            alt="Private pay"
          />
        );
    }
  };

  useEffect(() => {
    setPatientGlobalMessage(selectedPatient?.globalMessage ?? "");
  }, [selectedPatient]);

  const HealthcardStatus = () => {
    if (selectedPatient.healthcardLastValidated) {
      if (selectedPatient.healthcardValid) {
        return (
          <SCNTooltip>
            <TooltipTrigger>
              <Image
                className="icon center-icon h-4 pl-0.5"
                src={CheckmarkImage}
                alt="Checkmark"
              />
            </TooltipTrigger>
            <TooltipContent
              side="right"
              backgroundColorTWUtilClassName="bg-valid-green"
              arrowFillColorTWUtilClassName="fill-valid-green"
            >
              Card valid
            </TooltipContent>
          </SCNTooltip>
        );
      }
      return (
        <SCNTooltip>
          <TooltipTrigger>
            <Image
              className="icon center-icon h-4 pl-0.5"
              src={XMarkImage}
              alt="Xmark"
            />
          </TooltipTrigger>
          <TooltipContent
            side="right"
            backgroundColorTWUtilClassName="bg-invalid-red"
            arrowFillColorTWUtilClassName="fill-invalid-red"
          >
            Card invalid
          </TooltipContent>
        </SCNTooltip>
      );
    }
    return <></>;
  };

  return (
    <>
      {Object.keys(selectedPatient ?? {}).length > 0 && (
        <div
          className="patient-persist-insurance-header"
          style={{ backgroundColor: getInsuranceBackgroundColour(selectedPatient?.healthcardProvince) }}
        >
          {getInsuranceIcon(selectedPatient?.healthcardProvince)}
        </div>
      )}
      {/* TODO: restyle this properly */}
      <div
        className="z-[100] flex bg-primary px-2.5 py-0"
        style={{
          minWidth: "calc(100vw - 90px)",
          borderBottom: "3px solid #f7f7f8",
        }}
      >
        {/* <Row wrap={false}> */}
        <div className="min-w-0 flex-1 overflow-hidden">
          {Object.keys(selectedPatient ?? {}).length === 0 ? (
            <div className="my-0 ml-5 mr-0 flex h-full items-center text-xl font-semibold leading-5 text-primary-fg-blue">No Patient Selected</div>
          ) : (
            <div className="flex size-full justify-between gap-x-3 text-wrap">
              <Col className="p-1.5">
                <div className="flex gap-x-1.5">
                  <Typography.Text>
                    <strong style={{ fontWeight: "bolder" }}>{`${selectedPatient.lastname}, ${selectedPatient.firstname}${
                      (selectedPatient.preferredName ?? "") !== "" ? ` (${selectedPatient.preferredName})` : ""
                    } `}</strong>
                    {(() => {
                      switch (selectedPatient?.sex) {
                        case 0:
                          return "Male";
                        case 1:
                          return "Female";
                        case 2:
                          return "Non-Binary";
                        default:
                          return "Not Selected";
                      }
                    })()}
                  </Typography.Text>
                  <SCNButton
                    variant="ghost-no-hover"
                    size="icon-fill"
                    onClick={() => {
                      navigator.clipboard.writeText(selectedPatient?.patientUid);
                    }}
                  >
                    <Icon
                      className="text-button-01"
                      path={mdiContentCopy}
                      size="18px"
                    />
                  </SCNButton>
                  <SCNButton
                    variant="ghost-no-hover"
                    size="icon-fill"
                    onClick={() => setShowEditPatientInfoModal(true)}
                  >
                    <Icon
                      className="text-button-01"
                      path={mdiAccountEditOutline}
                      size="18px"
                    />
                  </SCNButton>
                  {selectedPatient.dontCall && (
                    <Icon
                      path={mdiPhoneOff}
                      size="18px"
                      color="#ca2a29"
                      className=""
                    />
                  )}
                  {selectedPatient.dontText && (
                    <Icon
                      path={mdiMessageOffOutline}
                      size="18px"
                      color="#ca2a29"
                      className=""
                    />
                  )}
                  {selectedPatient.dontEmail && (
                    <Icon
                      path={mdiEmailOffOutline}
                      size="18px"
                      color="#ca2a29"
                      className=""
                    />
                  )}
                  {selectedPatient.unsuccessfulIdentityVerificationAttempts === 3 && (
                    <Icon
                      path={mdiLock}
                      size="18px"
                      color="#7E84A3"
                      className=""
                    />
                  )}
                </div>
                <div className="flex items-center gap-x-1 whitespace-nowrap">
                  <strong className="">DoB:</strong>
                  {!isNil(selectedPatient?.birth) ? (
                    <>{formatFNS(parseFNS(selectedPatient?.birth?.slice(0, 10), "yyyy-MM-dd", new Date()), "MMM dd, yyyy")}</>
                  ) : (
                    ""
                  )}{" "}
                  {selectedPatient?.birth ? `(${differenceInYears(new Date(), new Date(selectedPatient.birth))} y/o)` : ""}
                </div>
                <div>
                  {selectedPatient.address}
                  {selectedPatient.apt ? `, ${selectedPatient.apt}` : ""}
                </div>
                <div>
                  {selectedPatient?.city || selectedPatient?.province
                    ? `${selectedPatient?.city ?? ""}, ${selectedPatient?.province ?? ""} ${selectedPatient?.postal ?? ""}`
                    : "No city or province selected"}
                </div>
              </Col>
              <Col
                className="patient-persist-insurance-card flex-shrink-0 p-1.5"
                style={{
                  backgroundColor: getInsuranceBackgroundColour(selectedPatient?.healthcardProvince, "33"),
                }}
              >
                <div className="flex items-center gap-x-1 whitespace-nowrap">
                  <strong className="">Insurer:</strong>
                  {selectedPatient?.healthcardProvince ? selectedPatient?.healthcardProvince : "No insurer selected"}
                </div>
                <div className="flex items-center gap-x-1 whitespace-nowrap">
                  <strong className="">Health card:</strong>
                  <div className="flex">
                    <div>
                      {selectedPatient?.healthcardNumber
                        ? `${selectedPatient?.healthcardNumber}${selectedPatient?.healthcardVersionCode ? "-" : ""}`
                        : ""}
                    </div>
                    <div>{selectedPatient?.healthcardVersionCode ? selectedPatient?.healthcardVersionCode : ""}</div>
                  </div>
                  <HealthcardStatus />
                </div>
                <Row>
                  <HealthcardModal images={selectedPatient?.insurancePhotos} />
                </Row>
                <br />
                {/* See card button will go here */}
              </Col>
              <Col className="p-1.5">
                {selectedPatient.preferredContactId ? (
                  <Row style={{ margin: 0 }}>
                    <Col
                      span={2}
                      className="patient-persist-col"
                    />
                    <Col
                      span={22}
                      className="patient-persist-col"
                    >
                      <div className="flex items-center gap-x-1 whitespace-nowrap">
                        <strong className="">Next of Kin:</strong>
                        {primaryAltContact.lastname + ", " + primaryAltContact.firstname + " "}
                        <i>({primaryAltContact.relationship})</i>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}

                <Row>
                  <Col
                    span={2}
                    className="patient-persist-col"
                  >
                    <CaretRightOutlined className={`patient-persist-inline-icon ${preferredContactMethod === "homePhoneCall" ? "" : "hidden"}`} />
                  </Col>
                  <Col
                    span={22}
                    className="patient-persist-col"
                  >
                    <div className={cn(`flex items-center gap-x-1 whitespace-nowrap`, selectedPatient.dontCall && "text-[#ca2a29]")}>
                      <strong className="">Home Phone:</strong>
                      {selectedPatient.preferredContactId
                        ? primaryAltContact.homephone || "Not entered"
                        : selectedPatient?.homephone || "Not entered"}
                    </div>
                  </Col>
                </Row>

                <Row className="">
                  <Col
                    span={2}
                    className="patient-persist-col"
                  >
                    <CaretRightOutlined
                      className={`patient-persist-inline-icon ${
                        preferredContactMethod === "busPhoneText" || preferredContactMethod === "busPhoneCall" ? "" : "hidden"
                      }`}
                    />
                  </Col>
                  <Col
                    span={22}
                    className="patient-persist-col"
                  >
                    <div
                      className={cn(
                        `flex items-center gap-x-1 whitespace-nowrap`,
                        selectedPatient.dontCall && selectedPatient.dontText ? "text-[#ca2a29]" : ""
                      )}
                    >
                      <strong className="">Cell Phone:</strong>
                      {selectedPatient.preferredContactId ? primaryAltContact.busphone || "Not entered" : selectedPatient?.busphone || "Not entered"}
                      {(selectedPatient?.busphone && selectedPatient.dontText && !selectedPatient.dontCall) ||
                      preferredContactMethod === "busPhoneCall" ? (
                        <i className="patient-persist-cell-phone-use-font">(Call)</i>
                      ) : (
                        ""
                      )}
                      {(selectedPatient?.busphone && selectedPatient.dontCall && !selectedPatient.dontText) ||
                      preferredContactMethod === "busPhoneText" ? (
                        <i className="patient-persist-cell-phone-use-font">(Text Message)</i>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col
                    span={2}
                    className="patient-persist-col"
                  >
                    <CaretRightOutlined className={cn(`patient-persist-inline-icon`, preferredContactMethod === "email" && "hidden")} />
                  </Col>
                  <Col
                    span={22}
                    className="patient-persist-col"
                  >
                    <div
                      className={cn(
                        `flex items-center gap-x-1 whitespace-nowrap`,
                        selectedPatient.dontEmail && "patient-persist-communication-opt-out-font"
                      )}
                    >
                      <strong className="">Email:</strong>
                      <div>
                        {selectedPatient.preferredContactId ? primaryAltContact.email || "Not entered" : selectedPatient?.email || "Not entered"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="mr-4 flex gap-x-3 p-1.5">
                <div className="">
                  <div className="flex items-center gap-x-1 whitespace-normal">
                    <strong className="">Referring Provider:</strong>
                    {selectedPatient?.patientReferringDoctorId ? doctorName : "N/A"}
                  </div>
                  <div className="flex items-center gap-x-1 whitespace-nowrap">
                    <strong className="">Billing:</strong>
                    {selectedPatient?.patientReferringDoctorId ? doctorBillingNumber : "N/A"}
                  </div>
                  <Row className="mt-2">
                    <SCNPopover open={showGlobalMessage}>
                      <PopoverContent className="p-0">
                        <SCNCard className="border-none">
                          <CardHeader className="p-4">
                            <CardTitle>Global Message</CardTitle>
                          </CardHeader>
                          <CardContent className={cn("p-4", "pt-0")}>
                            <SCNTextarea
                              className="min-h-[200px] w-[400px]"
                              value={patientGlobalMessage}
                              onChange={(e) => setPatientGlobalMessage(e.target.value)}
                            />
                          </CardContent>
                          <CardFooter>
                            <SCNButton onClick={(v) => updatePatientDataMutation.mutate({ ...selectedPatient, globalMessage: patientGlobalMessage })}>
                              Save
                            </SCNButton>
                          </CardFooter>
                        </SCNCard>
                        {/* <Form
                          form={headerForm}
                          // onFinish={(v) => onGlobalMessageSave(v.message, selectedPatient)}
                          onFinish={(v) => updatePatientDataMutation.mutate({ ...selectedPatient, globalMessage: v.message })}
                          style={{ width: 500, marginBottom: -24 }}
                        >
                          <Title
                            level={5}
                            style={{ marginTop: 0 }}
                          >
                            Global Message
                          </Title>
                          <Form.Item
                            name="message"
                            initialValue={selectedPatient?.globalMessage}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              maxLength={255}
                              showCount={true}
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              htmlType="submit"
                              type="primary"
                              onClick={() => setShowGlobalMessage(false)}
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </Form> */}
                      </PopoverContent>
                      <PopoverTrigger onClick={() => setShowGlobalMessage((prev) => !prev)}>
                        <div className={cn(`rounded-md py-1.5 text-center`, selectedPatient.globalMessage && "bg-[#bed5fc] px-4")}>
                          <div className="flex items-center gap-x-2.5">
                            <div className={cn(`font-bold`, selectedPatient.globalMessage && `text-primary-fg-blue`)}>Global Message</div>
                            {showGlobalMessage ? (
                              <UpSquareFilled
                                className={cn(
                                  `-m-1 text-[1.5em]`,
                                  (selectedPatient?.globalMessage ?? "") === "" ? "text-primary-fg-stroke" : "text-primary-fg-blue"
                                )}
                              />
                            ) : (
                              <DownSquareFilled
                                className={cn(
                                  `-m-1 text-[1.5em]`,
                                  (selectedPatient?.globalMessage ?? "") === "" ? "text-primary-fg-stroke" : "text-primary-fg-blue"
                                )}
                              />
                            )}
                          </div>
                        </div>
                      </PopoverTrigger>
                    </SCNPopover>
                  </Row>
                </div>
                <Image
                  className="h-fit cursor-pointer"
                  src={`${basePath}/PatientClear.png`}
                  alt="Clear patient"
                  width={22}
                  height={22}
                  onClick={() => {
                    onClear();
                  }}
                />
              </Col>
            </div>
          )}
        </div>
        <Col className="py-1.5 [text-wrap:nowrap]">
          <LoggedInUser
            // user={user}
            setNotesOpen={setNotesOpen}
          />
        </Col>
        {/* </Row> */}
        {showEditPatientInfoModal && (
          <EditPatientInfoModal
            showEditPatientInfoModal={showEditPatientInfoModal}
            setShowEditPatientInfoModal={setShowEditPatientInfoModal}
            patientInfo={selectedPatient}
          />
        )}
      </div>
    </>
  );
};

interface HealthcardModalProps {
  images: string[] | null;
}

function HealthcardModal({ images }: HealthcardModalProps) {
  const carouselRef = useRef<CarouselRef>(null);

  const [healthCardModalVisible, setHealthCardModalVisible] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [colour, setColour] = useState("black");
  const [cursor, setCursor] = useState("default");
  const [text, setText] = useState("No Card");

  useEffect(() => {
    if (images && images.length > 0) {
      setColour("#0040ff");
      setCursor("pointer");
      setText("View Card");
    }
    else {
      setColour("black");
      setCursor("default");
      setText("No Card");
    }
  }, [images]);

  return (
    <>
      {healthCardModalVisible && images && (
        <Modal
          className="patient-persist-healthcard-carousel"
          open={healthCardModalVisible}
          onCancel={() => {
            setCarouselIndex(0);
            setHealthCardModalVisible(false);
          }}
          title={"Health Card"}
          footer={null}
          // width="40vw"
          centered
          style={{ borderRadius: "15px", overflow: "hidden" }}
        >
          <Carousel
            style={{ margin: "15px" }}
            ref={carouselRef}
            dots={false}
            afterChange={(currentSlide) => {
              setCarouselIndex(currentSlide);
            }}
            waitForAnimate={false}
            arrows
            nextArrow={<RightOutlined />}
            prevArrow={<LeftOutlined />}
          >
            {images.map((image: string, index: number) => {
              return (
                <ImageCropper
                  height="40vh"
                  key={`${index}`}
                  src={image}
                  preview
                />
              );
            })}
          </Carousel>
          <Divider />
          <div className="preview-images">
            {images.map((image, index) => (
              <ImageCropper
                height="11vh"
                width="15vh"
                key={`${index}`}
                src={image}
                alt={`Insurance Photo ${index + 1}`}
                style={{
                  borderRadius: "10px",
                  border: carouselIndex === index ? "2px solid #0058f8" : "2px solid transparent",
                  margin: "5px",
                  cursor: "pointer",
                }}
                onClick={() => carouselRef.current?.goTo(index)}
                preview={false}
              />
            ))}
          </div>
        </Modal>
      )}
      {
        <div
          className="patient-persist-healthcard-preview-text"
          style={{
            cursor: cursor,
            color: colour,
          }}
          onClick={() => {
            if ((images ?? []).length > 0) {
              setHealthCardModalVisible(true);
            }
          }}
        >
          {text}
          <Image
            src={(images ?? []).length > 0 ? CardImage : CardBlackImage}
            className="patient-persist-healthcard-preview-icon"
            alt="Card Icon"
          />
        </div>
      }
    </>
  );
}
