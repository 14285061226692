"use client";
import { signIn, useSession } from "next-auth/react";
import { usePathname } from "next/navigation";
import { PropsWithChildren, useEffect } from "react";

import "./authLayout.scss";

const AuthLayout = ({ children }: PropsWithChildren) => {
  const { status, data: session } = useSession();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname?.toLowerCase()?.includes("/unsubscribepatient")) {
      return;
    }
    if (status === "unauthenticated" && !pathname?.startsWith("/auth")) {
      localStorage.setItem("justLoggedIn", "true");
      signIn("azure-ad-b2c", undefined, { prompt: "login" });
      // TODO: we may use below and remove localStorage use when we can pull roles from AD directly rather than fetching
      // unfortunately signIn is a client component (next-auth v4 at least)
      // we can redirect to /api/auth/signin but will be promped to select provider
      // signIn("azure-ad-b2c", { callbackUrl: "/appointments" }, { prompt: "login" });
    }

    // if (status === "authenticated") {
    //   console.log(`session after authenticated: `, session);
    // }
  }, [status, pathname]);

  if (pathname?.toLowerCase()?.includes("/unsubscribepatient")) {
    return <>{children}</>;
  }

  return (
    <>
      {status === "unauthenticated" ? (
        <div className="absolute top-[35%] flex size-full flex-col gap-3">
          <svg
            viewBox="0 0 800 800"
            className="h-[300px]"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="spin2"
              cx="400"
              cy="400"
              fill="none"
              r="224"
              stroke="#008eff"
              strokeWidth="36"
              strokeDasharray="442 1400"
              strokeLinecap="round"
            />
          </svg>
          <div className="flex justify-center text-lg text-gray-600">Redirecting to login page</div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default AuthLayout;
