import Image from "next/image";
import Link from "next/link";

const basePath = process.env.NEXT_PUBLIC_BASENAME ?? "";

export const HomeButton = () => {
  return (
    <Link
      // href={`${basePath}/`}
      href={`//`}
      className="my-2.5 flex justify-center"
    >
      <Image
        src={`${basePath}/skinopathy_white_logo.png`}
        alt="Skinopathy logo"
        width={50}
        height={75.34}
      />
    </Link>
  );
};
