import { Image } from "antd";
import "./ImageCropper.scss";
import { CSSProperties } from "react";

const basePath = process.env.NEXT_PUBLIC_BASENAME ?? "";

interface ImageCropperProps {
  src: string | undefined;
  width?: string;
  height?: string;
  alt?: string;
  preview?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

export const ImageCropper = ({ src, width, height, alt, preview, style, onClick }: ImageCropperProps) => {
  const containerStyle = {
    width: width ? width : "100%",
    height: height ? height : "300px", // Default height if not provided
    overflow: "hidden",
  };

  return (
    <div
      className="image-cropper"
      style={containerStyle}
    >
      <div className="image-container">
        <Image
          style={style}
          src={src}
          alt={alt}
          preview={preview}
          onClick={onClick}
          fallback={`${basePath}/icon192.png`}
        />
      </div>
    </div>
  );
};
