import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { healthcardProvinceOptions, userProvinceOptions } from "@/lib/types/constants";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import { AddressBookPhysician } from "@/lib/types/addressBook";
import {
  selectedPatientAtom,
  selectedPharmacyAtom,
  // selectedTemplatePatientAtom
} from "@/lib/states/globalStates";
import { useAtom } from "jotai";
import { EMRPatient } from "@/lib/types/patient";
import { isValid, parse as parseFNS, format as formatFNS } from "date-fns";
import { isNil } from "lodash";
import patientAPIClient from "@/api/PatientAPIClient";
import { PhysicianLocationModel } from "@/lib/types/physician";
import { DateSelector } from "@/components/DateAndTimeSelector/DateSelector";

import "./editPatientInfoModal.scss";
import { PhysicianDropdownLegacy } from "@/components/physicianDropdown/physicianDropdownLegacy";
import { PharmacyDropdownLegacy } from "@/components/pharmacyDropdown/pharmacyDropdownLegacy";
import { Pharmacy } from "@/lib/types/pharmacy";
import { toast } from "sonner";

interface EditPatientInfoModalProps {
  setShowEditPatientInfoModal: Dispatch<SetStateAction<boolean>>;
  showEditPatientInfoModal: boolean;
  patientInfo: EMRPatient;
}

export const EditPatientInfoModal = ({ setShowEditPatientInfoModal, showEditPatientInfoModal, patientInfo }: EditPatientInfoModalProps) => {
  const handlePhysicianChange = (
    physician: AddressBookPhysician,
    setDoctor: Dispatch<SetStateAction<AddressBookPhysician | undefined>>,
    setDoctorLocation: Dispatch<SetStateAction<PhysicianLocationModel | undefined>>,
    location?: PhysicianLocationModel
  ) => {
    setDoctor(physician);
    if (location) {
      setDoctorLocation(location);
    }
    else {
      setDoctorLocation(undefined);
    }
  };

  const [, setRefByDoctor] = useState<AddressBookPhysician>();
  const [refByDoctorLocation, setRefByDoctorLocation] = useState<PhysicianLocationModel>();
  const [, setFamDoctor] = useState<AddressBookPhysician>();
  const [famDoctorLocation, setFamDoctorLocation] = useState<PhysicianLocationModel>();
  const [, setSelectedPharmacy] = useAtom(selectedPharmacyAtom);
  const [form] = Form.useForm();
  const [, setSelectedPatient] = useAtom<EMRPatient>(selectedPatientAtom);

  useEffect(() => {
    const patientFormInfo = {
      ...patientInfo,
      birth: isNil(patientInfo?.birth) ? undefined : parseFNS(patientInfo?.birth?.slice(0, 10), "yyyy-MM-dd", new Date()),
      healthcardExpiryDate: isNil(patientInfo?.healthcardExpiryDate)
        ? undefined
        : parseFNS(patientInfo?.healthcardExpiryDate?.slice(0, 10), "yyyy-MM-dd", new Date()),
    };

    form.setFieldsValue(patientFormInfo);
  }, [patientInfo]);

  const updatePatientMutation = useMutation({
    mutationFn: async (transformedPatientInfo: EMRPatient) => {
      await patientAPIClient.updatePatient(transformedPatientInfo);
    },
    onSuccess: (data, variables, context) => {
      setSelectedPatient(variables);
      toast.success("Patient Information Updated.");
      setShowEditPatientInfoModal(false);
    },
    onError: (error) => {
      toast.error("Error saving patient information.");
      console.error("Failed to save patient information:", error);
    },
  });

  return (
    <Modal
      wrapClassName="edit-patient-info-modal-container"
      width={450}
      title="Patient Info"
      open={showEditPatientInfoModal}
      closable={false}
      destroyOnClose={true}
      onCancel={() => setShowEditPatientInfoModal(false)}
      footer={[
        <Button
          type="primary"
          key="button-1"
          onClick={async () => {
            try {
              await form.validateFields();

              const patientFormInfo: Partial<EMRPatient> = {
                ...form.getFieldsValue(),
                birth: !isValid(form.getFieldValue("birth")) ? null : formatFNS(form.getFieldValue("birth") as Date, "yyyy-MM-dd"),
                healthcardExpiryDate: !isValid(form.getFieldValue("healthcardExpiryDate"))
                  ? null
                  : formatFNS(form.getFieldValue("healthcardExpiryDate") as Date, "yyyy-MM-dd"),
                patientFamilyDoctorLocationId:
                  form.getFieldValue("patientFamilyDoctorId") === undefined
                    ? null
                    : (famDoctorLocation?.id ?? patientInfo.patientFamilyDoctorLocationId),
                patientReferringDoctorLocationId:
                  form.getFieldValue("patientReferringDoctorId") === undefined
                    ? null
                    : (refByDoctorLocation?.id ?? patientInfo.patientReferringDoctorLocationId),
              };
              const transformedPatientInfo: EMRPatient = { ...patientInfo, ...patientFormInfo };
              updatePatientMutation.mutate(transformedPatientInfo);
            }
            catch (e) {
              console.error(e);
            }
          }}
        >
          Confirm Changes
        </Button>,
        <Button
          key="button-2"
          onClick={() => setShowEditPatientInfoModal(false)}
        >
          Cancel
        </Button>,
      ]}
    >
      <Form
        className="patient-info-row-container"
        form={form}
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 13 }}
        name="patient-info-form"
      >
        <Form.Item
          className="patient-info-row"
          name="firstname"
          label="First Name"
          key="firstname"
          rules={[{ required: true, message: "Missing first name" }]}
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="lastname"
          label="Last Name"
          key="lastname"
          rules={[{ required: true, message: "Missing last name" }]}
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="birth"
          label="Date of Birth"
          key="birth"
        >
          <DateSelector
            className="patient-info-row-value date-selector"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="sex"
          label="Gender"
          key="sex"
        >
          <Select
            className="patient-info-row-value"
            size="small"
            options={[
              {
                key: 0,
                value: 0,
                label: "Male",
              },
              {
                key: 1,
                value: 1,
                label: "Female",
              },
              {
                key: 2,
                value: 2,
                label: "Non-Binary",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="healthcardProvince"
          label="Insurer"
          key="healthcardProvince"
        >
          <Select
            className="patient-info-row-value"
            size="small"
          >
            {healthcardProvinceOptions &&
              healthcardProvinceOptions.map((item: string) => (
                <Select.Option
                  key={item}
                  value={item}
                >
                  {item}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="healthcardNumber"
          label="Health Card Number"
          key="healthcardNumber"
          rules={[{ required: true, message: "Missing Health card number" }]}
        >
          <Input // TODO: Change this, phone number, maybe even dates to using MaskedInput
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="healthcardVersionCode"
          label="Health Card Version Code"
          key="healthcardVersionCode"
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="healthcardExpiryDate"
          label="Health Card Expiry Date"
          key="healthcardExpiryDate"
        >
          <DateSelector
            className="patient-info-row-value date-selector"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="email"
          label="Email"
          key="email"
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="homephone"
          label="Home Phone"
          key="homephone"
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="busphone"
          label="Cell Phone"
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <div className="patient-info-address-title-container">ADDRESS</div>

        <Form.Item
          className="patient-info-row"
          name="address"
          label="Address 1"
          key="address"
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="apt"
          label="Address 2"
          key="apt"
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="city"
          label="City"
          key="city"
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="province"
          label="Province"
          key="province"
        >
          <Select
            className="patient-info-row-value"
            size="small"
          >
            {userProvinceOptions &&
              userProvinceOptions.map((item) => (
                <Select.Option
                  key={item}
                  value={item}
                >
                  {item}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="postal"
          label="Postal Code"
          key="postal"
        >
          <Input
            className="patient-info-row-value"
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="patientReferringDoctorId"
          label="Referring Provider"
          key="patientReferringDoctorId"
        >
          <PhysicianDropdownLegacy
            className="patient-info-row-value"
            onChange={(physician, location) => {
              handlePhysicianChange(physician, setRefByDoctor, setRefByDoctorLocation, location);
              form.setFieldValue("patientReferringDoctorId", physician.id);
              setRefByDoctorLocation(location);
            }}
            defaultId={form.getFieldValue("patientReferringDoctorId")}
            dropdownWidth={350}
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="patientFamilyDoctorId"
          label="Family Provider"
          key="patientFamilyDoctorId"
        >
          <PhysicianDropdownLegacy
            className="patient-info-row-value"
            onChange={(physician, location) => {
              handlePhysicianChange(physician, setFamDoctor, setFamDoctorLocation, location);
              form.setFieldValue("patientFamilyDoctorId", physician.id);
              setFamDoctorLocation(location);
            }}
            defaultId={form.getFieldValue("patientFamilyDoctorId")}
            dropdownWidth={350}
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="familyPhysicianNotificationStatus"
          label="Notify Family Provider"
          key="familyPhysicianNotificationStatus"
          valuePropName="checked"
        >
          <Checkbox
            className="patient-info-row-value checkbox"
            checked={patientInfo.familyPhysicianNotificationStatus ?? false}
            onChange={(e) => form.setFieldValue("familyPhysicianNotificationStatus", e.target.checked)}
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="pharmacyId"
          label="Pharmacy"
          key="pharmacyId"
        >
          <PharmacyDropdownLegacy
            className="patient-info-row-value"
            onChange={(pharmacy: Pharmacy) => {
              setSelectedPharmacy(pharmacy);
              form.setFieldValue("pharmacyId", pharmacy.id);
            }}
            defaultId={form.getFieldValue("pharmacyId")}
            dropdownWidth={350}
            size="small"
          />
        </Form.Item>

        <Form.Item
          className="patient-info-row"
          name="pharmacyDeliveryStatus"
          label="Deliver Prescriptions"
          valuePropName="checked"
        >
          <Checkbox
            className="patient-info-row-value checkbox"
            checked={patientInfo.pharmacyDeliveryStatus ?? false}
            onChange={(e) => form.setFieldValue("pharmacyDeliveryStatus", e.target.checked)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
