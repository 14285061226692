import { HomeButton } from "./homeButton";

import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { cn } from "@/lib/utils/cssUtils";
import { Tooltip as SCNTooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";

const basePath = process.env.NEXT_PUBLIC_BASENAME ?? "";

export const NotDoctorButUserMenu = () => {
  const pathname = usePathname();
  const activatedLinkClasses = "w-full rounded-none bg-[#6c717a]";

  return (
    <div>
      <HomeButton />

      <div className="mt-8 flex flex-col items-center justify-center gap-2.5">
        <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link
              href={`/appointments`}
              className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/appointments" && activatedLinkClasses)}
            >
              <Image
                src={`${basePath}/Scheduler.svg`}
                alt="Appointments"
                height={30}
                width={30}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Appointments</TooltipContent>
        </SCNTooltip>
        <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link
              href={`/taskNotes`}
              className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/taskNotes" && activatedLinkClasses)}
            >
              <Image
                src={`${basePath}/Communications.svg`}
                alt="Communications"
                height={30}
                width={30}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Communications</TooltipContent>
        </SCNTooltip>
        <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link
              href={`/patientManagement`}
              className={cn(
                `flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`,
                pathname === "/patientManagement" && activatedLinkClasses
              )}
            >
              <Image
                src={`${basePath}/Patient Details.svg`}
                alt="Patient Management"
                height={30}
                width={30}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Patient Management</TooltipContent>
        </SCNTooltip>
        <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link
              href={`/clinicalChart`}
              className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/clinicalChart" && activatedLinkClasses)}
            >
              <Image
                src={`${basePath}/Virtual Chart.svg`}
                alt="Clinical Chart"
                height={30}
                width={30}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Clinical Chart</TooltipContent>
        </SCNTooltip>
        <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link
              href={`/billing`}
              className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/billing" && activatedLinkClasses)}
            >
              <Image
                src={`${basePath}/Billing.svg`}
                alt="Billing"
                height={30}
                width={30}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Billing</TooltipContent>
        </SCNTooltip>
        <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link
              href={`/documentManager`}
              className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/documentManager" && activatedLinkClasses)}
            >
              <Image
                src={`${basePath}/Documents.svg`}
                alt="Document Manager"
                height={30}
                width={30}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Document Manager</TooltipContent>
        </SCNTooltip>
        <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link
              href={`/addressBook`}
              className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/addressBook" && activatedLinkClasses)}
            >
              <Image
                src={`${basePath}/Contacts.svg`}
                alt="Address Book"
                height={30}
                width={30}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Address Book</TooltipContent>
        </SCNTooltip>
      </div>
    </div>
  );
};
