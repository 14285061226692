import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";

export interface TextareaProps extends ComponentProps<"textarea"> {}

// TODO: support the styling classes used here
const Textarea = ({ className, ...props }: TextareaProps) => {
  return (
    <textarea
      className={cn(
        "border-input placeholder:text-placeholder-fg focus-visible:ring-ring flex min-h-[60px] w-full rounded-md border bg-transparent px-3 py-2 text-sm shadow-sm focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      {...props}
    />
  );
};
Textarea.displayName = "Textarea";

export { Textarea };
