// TODO: all these are probably going to change in the near future, but I want to improve what is there currently to a usable state

import { SaveTaskNote, TaskNote } from "@/lib/types/taskNote";
import customFetch from "./customFetch";

const getAllDoctorTaskNotes = async (): Promise<TaskNote[]> => {
  // TODO: this is stupid in that why am i getting ALL tasks for ALL patients and ALL doctors??
  const { tasks } = await customFetch(`/tasks`);
  return tasks;
};

const saveDoctorTaskNote = async ({ patientId, taskNote }: SaveTaskNote): Promise<number> => {
  const { taskId } = await customFetch(`/tasks`, { body: { patientId, taskNote }, method: "POST" });
  return taskId;
};

const markTaskAsDone = async (id: number): Promise<string> => {
  const { message } = await customFetch(`/tasks/done`, { body: { id }, method: "PUT" });
  return message;
};

const tasksAPI = {
  getAllDoctorTaskNotes,
  saveDoctorTaskNote,
  markTaskAsDone,
};

export default tasksAPI;
