import { AutoComplete, Typography, Select, Card, Space } from "antd";
import { PushpinFilled } from "@ant-design/icons";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { AxiosPromise } from "axios";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import pharmacyAPIClient from "@/api/PharmacyAPIClient";
import { selectedPharmacyAtom } from "@/lib/states/globalStates";
import { userProvinceMap } from "@/lib/types/constants";
import "./pharmacyDropdown.scss";
import { Pharmacy } from "@/lib/types/pharmacy";
import { BackendPharmacyInterface } from "@/lib/types/pharmacy";
import { toast } from "sonner";

const { Paragraph } = Typography;
const { Option } = Select;

const getPharmacyLabel = (pharmacy: Pharmacy) => {
  let label = "";
  if (pharmacy.name) {
    label += pharmacy.name;
  }
  if (pharmacy.city) {
    label += ` | ${pharmacy.city}`;
  }
  if (pharmacy.fax) {
    label += ` | F: ${pharmacy.fax}`;
  }
  return { label, value: pharmacy.id };
};

const getPharmacyAddress = (pharmacy: Pharmacy) => {
  const streetAddress = pharmacy.streetAddress ? pharmacy.streetAddress : "...";
  const city = pharmacy.city ? pharmacy.city : "...";
  const province = pharmacy.province ? userProvinceMap[pharmacy.province] : "...";
  const postal = pharmacy.postal ? pharmacy.postal : "...";
  return `${streetAddress}, ${city} ${province}, ${postal}`;
};

const createLabeledOptionsForPharmacies = (pharmacies: Pharmacy[]) => {
  return pharmacies.map((pharmacy) => getPharmacyLabel(pharmacy));
};

export const getDefaultPharmacy = async (id: number, callback: Function) => {
  try {
    const pharmacy = await pharmacyAPIClient.getPharmacy(id);
    callback(pharmacy);
  }
  catch (_) {
    toast.error("Error getting pharmacy.");
    return;
  }
};

interface PharmacyDropdownProps {
  defaultId?: number | null;
  onChange?: (arg: Pharmacy) => any;
  setPrescriptionFaxTo?: Function;
  width?: number;
  className?: string;
  size?: SizeType;
  dropdownWidth?: boolean | number;
  placeholder?: string;
}

export const PharmacyDropdownLegacy = ({
  defaultId,
  onChange,
  setPrescriptionFaxTo,
  width,
  className,
  size,
  dropdownWidth,
  placeholder,
}: PharmacyDropdownProps) => {
  const [pharmaciesFound, setPharmaciesFound] = useState<Pharmacy[]>();
  const [, setPharmacySearchOptions] = useState<{ value: number; label: string }[]>();
  const [selectedPharmacy, setSelectedPharmacy] = useAtom(selectedPharmacyAtom);
  const [selectedPharmacyLabel, setSelectedPharmacyLabel] = useState(getPharmacyLabel(selectedPharmacy).label);

  useEffect(() => {
    const getPharmacy = async () => {
      if (defaultId) {
        await getDefaultPharmacy(defaultId, (pharmacy: BackendPharmacyInterface) => {
          const defaultPharmacySelected = {
            id: pharmacy.id,
            address: pharmacy.address,
            streetAddress: pharmacy.streetAddress,
            streetAddress2: pharmacy.streetAddress2,
            city: pharmacy.city,
            province: pharmacy.province,
            postal: pharmacy.postal,
            fax: pharmacy.fax,
            name: pharmacy.name,
            storeNumber: pharmacy.storeNumber,
            phone: pharmacy.phone,
            email: pharmacy.email,
            status: pharmacy.status,
            itemType: pharmacy.status,
          };
          setSelectedPharmacy(defaultPharmacySelected);
          setSelectedPharmacyLabel(getPharmacyLabel(defaultPharmacySelected).label);
          if (setPrescriptionFaxTo) {
            setPrescriptionFaxTo(defaultPharmacySelected.fax ? defaultPharmacySelected.fax : "");
          }
        });
      }
      else {
        setSelectedPharmacy({} as Pharmacy);
        setSelectedPharmacyLabel("");
      }
    };
    getPharmacy();
  }, [defaultId, setPrescriptionFaxTo, setSelectedPharmacy]);

  const handleSearch = async (searchText: string) => {
    setSelectedPharmacyLabel(searchText);
    if (!searchText) {
      setPharmaciesFound([] as Pharmacy[]);
      return;
    }
    pharmacyAPIClient.searchPharmacies(searchText, async (axiProm: AxiosPromise) => {
      const result = await axiProm;
      const pharmaciesSrc = result?.data?.pharmacies?.hits?.hits;
      const pharmacies: Pharmacy[] = pharmaciesSrc.map((p: { _source: Pharmacy }) => p._source);

      setPharmaciesFound(pharmacies);
      setPharmacySearchOptions(searchText ? createLabeledOptionsForPharmacies(pharmacies) : []);
    });
  };
  const onClear = () => {
    setSelectedPharmacyLabel("");
    setSelectedPharmacy({} as Pharmacy);
    if (onChange) {
      onChange({} as Pharmacy);
    }
  };
  const handleFocus = async () => {
    // Pins 2 requested pharmacies when the search text is blank or no search has been performed
    // TO DO: remove when pharmacy favourites feature is implemented
    if (!selectedPharmacyLabel || !pharmaciesFound || pharmaciesFound?.length === 0) {
      const pinnedPharmID1 = 1932; // ID is the same across prod and dev

      // Assign pharmacy IDs based on environment
      let pinnedPharmID2 = 0;
      if (process.env.NEXT_PUBLIC_ENV === "prod") {
        pinnedPharmID2 = 4941;
      }
      else if (process.env.NEXT_PUBLIC_ENV === "dev") {
        pinnedPharmID2 = 4923;
      }

      try {
        const [pharmacy1, pharmacy2] = await Promise.all([
          pharmacyAPIClient.getPharmacy(pinnedPharmID1),
          pharmacyAPIClient.getPharmacy(pinnedPharmID2),
        ]);

        const pinnedPharm1: Pharmacy = { pinned: true, ...pharmacy1 };
        const pinnedPharm2: Pharmacy = { pinned: true, ...pharmacy2 };
        setPharmaciesFound([pinnedPharm1, pinnedPharm2]);
      }
      catch (_) {
        toast.error("Error loading pinned pharmacies.");
        return;
      }
    }
  };
  return (
    <AutoComplete
      onFocus={handleFocus}
      className={className}
      style={{ width }}
      size={size}
      // options={pharmacySearchOptions}
      value={selectedPharmacyLabel}
      popupMatchSelectWidth={dropdownWidth}
      onSearch={handleSearch}
      placeholder={placeholder ?? "Pharmacy search"}
      onSelect={(value: any, params: any) => {
        const pharmacySelected = pharmaciesFound?.find((pharmacy) => pharmacy.id === Number(value));
        if (!pharmacySelected) {
          toast.error("Failed selecting pharmacy.");
          return;
        }

        // const label = params.label?.toString();
        setSelectedPharmacyLabel(getPharmacyLabel(pharmacySelected).label);
        setSelectedPharmacy(pharmacySelected);
        if (onChange) {
          onChange(pharmacySelected);
        }
      }}
      allowClear={true}
      onClear={onClear}
    >
      {pharmaciesFound ? (
        pharmaciesFound.map((pharmacy: Pharmacy) => (
          <Option
            key={pharmacy.id}
            value={pharmacy.id}
          >
            <Card
              size="small"
              className="pharmacy-dropdown-card"
            >
              <Space
                size="small"
                direction="vertical"
              >
                <PharmacyCard pharmacy={pharmacy} />
              </Space>
            </Card>
          </Option>
        ))
      ) : (
        <></>
      )}
    </AutoComplete>
  );
};

interface PharmacyCardProps {
  pharmacy?: Pharmacy;
  className?: string | undefined;
}

export function PharmacyCard({ pharmacy, className }: PharmacyCardProps) {
  return pharmacy && Object.keys(pharmacy ?? {}).length > 0 ? (
    <>
      <div className={className}>
        <Paragraph
          className="pharmacy-card-text"
          strong
        >
          <Space>
            {pharmacy.pinned && !className ? <PushpinFilled className="pharmacy-pin-icon" /> : <></>}
            {pharmacy.name}
          </Space>
        </Paragraph>
        <Paragraph className="pharmacy-card-text">{getPharmacyAddress(pharmacy)}</Paragraph>
        <Paragraph className="pharmacy-card-text">{pharmacy.phone ? `P: ${pharmacy.phone}` : ""}</Paragraph>
        <Paragraph className="pharmacy-card-text">{pharmacy.fax ? `F: ${pharmacy.fax}` : ""}</Paragraph>
      </div>
    </>
  ) : (
    <></>
  );
}
